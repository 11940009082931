import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [15,[2],[3]],
		"/(public)/cb/qb": [30,[13,14]],
		"/(app)/data": [16,[2,4],[3]],
		"/(app)/data/[type]": [17,[2,4,5],[3]],
		"/(app)/data/[type]/[id]": [18,[2,4,5,6],[3]],
		"/(app)/email": [19,[2,7],[3]],
		"/(app)/events": [20,[2,8],[3]],
		"/(app)/events/[id]": [21,[2,8,9],[3]],
		"/(app)/internal": [22,[2],[3]],
		"/(app)/internal/auto": [23,[2],[3]],
		"/(app)/login": [24,[2],[3]],
		"/(public)/menu/mry/flavors": [31,[13]],
		"/(public)/menu/mry/food": [32,[13]],
		"/(public)/menu/mry/icecream2": [34,[13]],
		"/(public)/menu/mry/icecream": [33,[13]],
		"/(public)/menu/mry/more": [35,[13]],
		"/(public)/menu/mry/promo": [36,[13]],
		"/(public)/menu/mry/specialties2": [38,[13]],
		"/(public)/menu/mry/specialties": [37,[13]],
		"/(app)/shops": [25,[2,10],[3]],
		"/(app)/shops/[id]": [26,[2,10],[3]],
		"/(app)/square": [27,[2,11],[3]],
		"/(app)/territories": [28,[2,12],[3]],
		"/(app)/territories/[id]": [29,[2,12],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';