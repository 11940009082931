import './app.scss';
import { createContext } from '@pbg.millies/common/firebase/context.js';
import type { HandleClientError } from '@sveltejs/kit';

// eslint-disable-next-line ts/naming-convention
export const handleError: HandleClientError = async ({ error, event }) => {
  if (error instanceof Error) {
    console.log('handleError', error.name, error.message, event);
    console.log(error.stack);
  } else {
    console.log('handleError', error, event);
  }
};

console.log('before createContext');
void createContext().then(() => {
  console.log('createContext complete');
});
console.log('after createContext');
// createContext({ forceProd: true });
// TODO: Initialize logging?
